<template>
  <div>
    <top-info v-model="customer" />

    <b-card
      no-body
      class="custom-border-top-1px table-small-scrollY"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >Lịch sử cập nhật ({{ historyUpdate.length }} lần)</b-badge>
      </b-card-header>

      <b-table
        ref="table-suggested-list-product"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        empty-text="Không có lịch sử nào"
        :items="historyUpdate"
        :fields="tableColumnsHistoryUpdate"
      >
        <template #cell(data.price)="data">
          <div class="text-primary text-nowrap">
            <span class="font-weight-bold">
              {{ formatNumber(data.item.data.min_price) }}-{{
                formatNumber(data.item.data.max_price)
              }} <small>{{
                (CURRENCY.find(item => item.value === data.item.data.currency) || {text: 'usd'}).text.toUpperCase()
              }}</small>
            </span>
          </div>
        </template>
        <template #cell(data.status)="data">
          <b-badge
            class="customer-badge"
            :class="{
              ['bg-' + data.item.data.status]: true,
            }"
          >
            {{ (STATUS_TYPE_CUSTOMER.find(item => item.value === data.item.data.status) || {}).text || '' }}
          </b-badge>
        </template>
      </b-table>

    </b-card>

    <form-base v-model="customer" />
    <form-detail v-model="customer" />

    <AttachmentsDetail v-model="customer" />

    <b-card
      no-body
      class="custom-border-top-1px"
    >
      <b-card-header>
        <b-badge
          variant="light-primary"
          class="font-small-4 px-1 py-50"
        >SẢN PHẨM PHÙ HỢP {{ totalSuggestedList ? `(${totalSuggestedList})` : '' }}
        </b-badge>
        <b-button
          variant="primary"
          size="sm"
          :disabled="!isDownloadedPdf || countBase64ImgLoading > countBase64ImgLoaded"
          @click="openModalExportPdf"
        >
          <feather-icon
            v-if="isDownloadedPdf"
            icon="SendIcon"
          />
          <b-spinner
            v-else
            small
          />
          {{ getStatusTextExportPdf }}
        </b-button>
      </b-card-header>

      <b-card-body>

        <b-row class="fit-filter">
          <b-col
            cols="12"
            lg="6"
          >
            <!--           đã xuất-->
            <dynamic-form
              :id="'fit-filter-is_not_exported'"
              :value="fitFilter.is_not_exported"
              :type="'radio'"
              :label="'Trạng thái'"
              :class="'form-group-hor'"
              :options="IS_NOT_EXPORTED"
              @update-value="val => fitFilter.is_not_exported = val ? 1 : ''"
            />
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!--              nhân viên-->
            <dynamic-form
              :id="'fit-filter-user_id'"
              :value="fitFilter.user_id"
              :type="'select'"
              :label="'Nhân viên'"
              text-field="fullname"
              value-field="id"
              :select-default="false"
              :class="'form-group-hor'"
              :options="users"
              @update-value="val => fitFilter.user_id = val"
            />
          </b-col>

          <b-col
            cols="12"
            lg="6"
          >
            <!--            Giá-->
            <div class="form-group-hor mb-1">
              <label>Giá (USD)</label>
              <div class="grid-2-columns">
                <b-input-group append="≤">
                  <b-form-input
                    v-model="fitFilter.price_from"
                    placeholder="Tối thiểu"
                  />
                </b-input-group>
                <b-input-group prepend="≤">
                  <b-form-input
                    v-model="fitFilter.price_to"
                    placeholder="Tối đa"
                  />
                </b-input-group>
              </div>
            </div>
          </b-col>
        </b-row>

        <p>
          Sản phẩm được chọn để xuất báo giá: <span class="text-primary">{{ suggestSelectedList.length }}</span>
        </p>
        <b-table
          ref="table-suggested-list-product"
          class="position-relative"
          responsive
          primary-key="id"
          show-empty
          bordered
          small
          empty-text="Không có kết quả nào"
          :items="suggestedList"
          :fields="tableColumnsSuggestedProduct"
        >
          <template #cell(checked)="data">
            <b-form-checkbox
              v-model="suggestSelectedObject[data.item.id]"
            />
          </template>

          <template #cell(image)="data">
            <b-media-aside class="mr-75">
              <b-avatar
                square
                size="64"
                :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
                :text="data.item.image ? '' : 'CL'"
                @img-error="() => showImage160.push(data.item.image)"
              />
            </b-media-aside>
          </template>

          <template #cell(title)="data">
            <span
              class="d-block font-weight-bold cursor-pointer"
              :class="data.item.close_deal_type === 'close-deal' ? 'text-danger': 'text-primary'"
              @click="handleRowClick(data.item)"
            >
              <template v-if="data.item.close_deal_type === 'close-deal'">[CLOSE DEAL]</template>
              {{ data.item.title }}</span>
            <div class="product-badge-wrapper">
              <b-badge
                v-if="data.item.is_not_exported"
                class="product-badge is-exported"
                variant="danger"
              >ĐÃ GỬI
              </b-badge>
            </div>
          </template>
          <template #cell(thoi-han-hop-dong)="data">
            <div class="text-primary text-nowrap">
              {{ data.item.expire_month === 0 ? 'Chưa rõ' : `${!parseTotalMonths(data.item.expire_month).years ? '' : parseTotalMonths(data.item.expire_month).years + ' năm '} ${!parseTotalMonths(data.item.expire_month).months ? '' : parseTotalMonths(data.item.expire_month).months + ' tháng'}` }}
            </div>
          </template>

        </b-table>
      </b-card-body>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSuggestedList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <actions
      :buttons="actionsButton"
      @click="handleActionsButton"
    />

    <b-modal
      id="modal-export-pdf"
      ref="modal-export-pdf"
      :title="'Sản phẩm sẽ được xuất báo giá: ' + suggestSelectedList.length"
      header-text-variant="primary"
      ok-only
      ok-title="Xuất báo giá"
      no-stacking
      size="xl"
      @ok="exportPdf"
    >
      <div>
        <small>(*) Chỉnh sửa <strong>NỘI DUNG</strong> chỉ có giá trị xuất File báo giá, sẽ không thay đổi dữ liệu của
          hệ thống</small>
        <p v-if="contentIncludesContact && contentIncludesContact.length">
          <br>
          <span class="text-warning">Sản phẩm có chứa nội dung <strong>Liên hệ</strong>:</span>
          <span class="text-primary"> {{ contentIncludesContact.join(', ') }}</span>
        </p>
      </div>
      <br>

      <b-table
        ref="table-export-product"
        class="position-relative"
        responsive
        primary-key="id"
        show-empty
        bordered
        small
        :items="suggestSelectedList"
        :fields="tableColumnsSuggestedProductExport"
      >
        <template #cell(checked)="data">
          <b-form-checkbox
            v-model="suggestSelectedObject[data.item.id]"
          />
        </template>

        <template #cell(image)="data">
          <b-media-aside class="mr-75">
            <b-avatar
              square
              size="64"
              :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
              :text="data.item.image ? '' : 'CL'"
              @img-error="() => showImage160.push(data.item.image)"
            />
          </b-media-aside>
        </template>

        <template #cell(content)="data">
          <quill-editor
            :id="'customer-form-editor-quill-export-pdf-' + data.item.id"
            :ref="'customer-form-editor-quill-export-pdf-' + data.item.id"
            v-model="data.item.content"
            :options="{ theme: 'bubble' }"
            style="min-height:150px"
          />
        </template>

        <!--        <template #cell(title)="data">-->
        <!--          <span-->
        <!--            class="d-block font-weight-bold cursor-pointer"-->
        <!--            :class="data.item.close_deal_type === 'close-deal' ? 'text-danger': 'text-primary'"-->
        <!--            @click="handleRowClick(data.item)"-->
        <!--          >-->
        <!--            <template v-if="data.item.close_deal_type === 'close-deal'">[CLOSE DEAL]</template>-->
        <!--            {{ data.item.title }}</span>-->
        <!--          <div class="product-badge-wrapper">-->
        <!--            <b-badge-->
        <!--              v-if="data.item.is_not_exported"-->
        <!--              class="product-badge is-exported"-->
        <!--              variant="danger"-->
        <!--            >ĐÃ GỬI-->
        <!--            </b-badge>-->
        <!--          </div>-->
        <!--        </template>-->
      </b-table>
    </b-modal>

    <vue-html2pdf
      ref="html2Pdf"
      :filename="'Báo giá'"
      :manual-pagination="true"
      :paginate-elements-by-height="1100"
      :pdf-quality="1"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgressExportPdf($event)"
      @hasDownloaded="hasDownloadedPdf($event)"
    >
      <section
        slot="pdf-content"
        style="padding: 1rem 0"
        class="pdf-content"
      >
        <!-- PDF Content Here -->
        <b-img
          class="top-banner"
          :src="require('@/assets/images/bg-export-excel.png')"
          alt="background export excel"
        />
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <br>
        <div class="d-flex align-items-center gap-2">
          <div
            v-if="userData.avatar"
            class="product-item-user"
          >
            <img
              :src="getUrlFile(userData.avatar)"
              :alt="userData.fullname"
              class="ml-50"
              style="width: 180px; height: 180px; object-fit: cover; border-radius: 50%;border: 2px solid #c29a42 !important;"
            >
          </div>
          <div class="top-info">
            <div class="fullname">
              <feather-icon
                icon="UserIcon"
                class="mr-50"
                style="color: #2b5c9d"
                size="20"
              />
              <span class="font-weight-bolder font-medium-2 mb-1 d-inline-block text-dark">{{
                userData.fullname
              }}</span>
            </div>
            <div class="mail">
              <feather-icon
                icon="MailIcon"
                class="mr-50"
                style="color: #2b5c9d"
                size="20"
              />
              <span class="font-weight-bolder font-medium-2 mb-1 d-inline-block text-dark">{{ userData.email }}</span>
            </div>
            <div class="phone">
              <img
                :src="require('@/assets/images/icons/icon-phone.png')"
                alt="icon phone"
                width="20px"
                class="mr-50"
              >
              <span class="font-weight-bolder font-medium-2 mb-1 d-inline-block text-dark">{{
                userData.phone
              }} ({{ userData.fullname }})</span>
            </div>
            <div class="website">
              <img
                :src="require('@/assets/images/icons/icon-earth.png')"
                alt="icon earth"
                width="20px"
                class="mr-50"
              >
              <a
                href="//connectland.vn"
                class="website font-weight-bolder font-medium-2 mb-1 d-inline-block text-dark"
              >connectland.vn</a>
            </div>
          </div>
        </div>
        <div class="html2pdf__page-break" />
        <div class="product-list">
          <div
            v-for="(item, idx) in suggestSelectedList"
            :key="'item-' + item.id"
            class="product-item"
          >
            <div class="d-flex align-items-start">
              <a
                class="text-primary product-title"
                :href="item.link"
              >
                {{ (idx + 1) + '. ' + item.title }}
              </a>
            </div>
            <div
              class="product-content"
            >
              <div>
                <div
                  class="pr-50"
                  v-html="item.content"
                />
              </div>
              <div class="product-content-images">
                <img
                  v-show="getFeaturedImage(item)"
                  :key="'product-content-images-' + refreshKey"
                  :src="getFeaturedImage(item)"
                  class="featured"
                  :alt="item.title"
                >
                <template v-for="img in getExportProductImage(item)">
                  <img
                    :key="'product-content-image-' + img.name + refreshKey"
                    :src="base64ImgObj[img.origin]"
                    class="product-image"
                    :alt="img.origin"
                  >
                </template>
              </div>
            </div>
            <br>
            <br>
            <div
              v-if="idx%3===2"
              class="html2pdf__page-break"
            />
          </div>
        </div>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BTable,
  BBadge,
  BAvatar,
  BMediaAside,
  BRow,
  BCol,
  BPagination,
  BCardBody,
  BButton,
  BFormCheckbox,
  BModal,
  BFormInput,
  BInputGroup,
  BImg, BSpinner,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import customerStoreModule from '@/views/customer/customerStoreModule'
import useCustomerDetail from '@/views/customer/detail/useCustomerDetail'
import FormBase from '@/views/customer/detail/FormBase.vue'
import vSelect from 'vue-select'
import FormDetail from '@/views/customer/detail/FormDetail.vue'
import Actions from '@core/components/fixed-button/Fixed-Right-Button.vue'
import AttachmentsDetail from '@/views/components/util/AttachmentsDetail.vue'
import TopInfo from '@/views/customer/detail/TopInfo.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import DynamicForm from '@core/dynamic-form/DynamicForm.vue'
import VueHtml2pdf from 'vue-html2pdf'
import useProduct from '@/views/product/useProduct'
import { quillEditor } from 'vue-quill-editor'

const MAX_IMAGE_FOR_ITEM = 1

export default {
  components: {
    quillEditor,
    BCard,
    BCardHeader,
    BBadge,
    BAvatar,
    FormBase,
    FormDetail,
    Actions,
    AttachmentsDetail,
    BModal,
    BTable,
    BImg,
    BSpinner,
    TopInfo,
    BMediaAside,
    vSelect,
    BRow,
    BCol,
    BPagination,
    BCardBody,
    DynamicForm,
    BButton,
    BFormCheckbox,
    BFormInput,
    BInputGroup,
    VueHtml2pdf,
  },
  props: {
    value: {
      type: Object,
      require: true,
      default: () => {
      },
    },
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  computed: {
    getStatusTextExportPdf() {
      if (this.countBase64ImgLoaded < this.countBase64ImgLoading) return `Tải ảnh...${this.countBase64ImgLoaded}/${this.countBase64ImgLoading}`
      if (this.isDownloadedPdf) return 'Xuất báo giá'
      return `Xuất PDF... ${this.progressExportPdf}%`
    },
    contentIncludesContact() {
      const ids = []
      this.suggestSelectedList.forEach(item => {
        if (item.content && item.content.includes('Liên hệ')) ids.push(item.id)
      })
      return ids
    },
    actionsButton() {
      const validActions = []
      if (ability()
        .can('edit', subject('CustomerCorner', this.customer))) {
        validActions.push(
          this.ACTIONS_BUTTON.EDIT,
          this.customer.is_close_deal ? this.ACTIONS_BUTTON.OPEN : this.ACTIONS_BUTTON.CLOSE,
        )
      }
      return validActions
    },
  },
  created() {
    this.routeParams = this.$route.params
    this.$call(this.fetchCustomer(this.routeParams.id, res => {
      Object.assign(this.customer, res.data)
    }))
    this.$call(this.fetchSuggestedList(this.routeParams.id))
    this.$call(this.fetchHistoryUpdate({
      id: this.routeParams.id,
    }))
    this.$call(store.dispatch('app/fetchUsers'))
  },
  methods: {
    parseTotalMonths(totalMonths) {
      const years = Math.floor(totalMonths / 12)
      const months = totalMonths % 12
      return { years, months }
    },
    onProgressExportPdf(progressNumber) {
      this.progressExportPdf = progressNumber
    },
    hasDownloadedPdf() {
      this.isDownloadedPdf = true
    },
    getFeaturedImage(item) {
      let urlImage = ''
      if (item.image) {
        urlImage = item.image
      } else {
        const imgs = this.getExportProductImage(item) || []
        if (imgs.length) {
          urlImage = imgs[0].origin
        }
      }
      return this.base64ImgObj[urlImage || ''] || ''
    },
    getExportProductImage(product) {
      const files = product.document_files || []
      if (!files.length) return []
      const imgs = files.filter(file => file.mime && file.mime.includes('image') && file.origin !== product.image) || []
      return imgs.slice(0, MAX_IMAGE_FOR_ITEM)
    },
    exportPdf() {
      if (this.suggestSelectedList.length) {
        const dataQuotation = {
          client_id: this.customer.id,
          product_ids: this.suggestSelectedList.map(item => item.id.toString()),
        }
        this.$call(this.quotation(dataQuotation))
          .then(res => {
            Object.assign(this.customer, res)
            this.progressExportPdf = 0
            this.isDownloadedPdf = false

            // chờ ảnh download tối đa 30s trước khi export
            this.$toastr('Vui lòng chờ xuất dữ liệu thành file PDF... Quá trình có thể mất vài giây!')
            let count = 0
            const waitingImgDownload = setInterval(() => {
              if (this.countBase64ImgLoading <= this.countBase64ImgLoaded
                || count >= 10 * 120) {
                clearInterval(waitingImgDownload)
                this.$refs.html2Pdf.generatePdf()
              }
              count += 1
            }, 100)
          })
      }
    },
    openModalExportPdf() {
      if (!this.suggestSelectedList.length) {
        this.$toastr('Cần chọn tối thiểu 1 sản phẩm', 'warning')
        return
      }
      this.suggestSelectedList.forEach(item => {
        this.getBase64Img(item.image)
        const imgs = this.getExportProductImage(item) || []
        imgs.forEach(imgObj => {
          this.getBase64Img(imgObj.origin)
        })
      })
      this.$refs['modal-export-pdf'].show()
    },
    async getBase64Img(img) {
      if (!img || this.base64ImgObj[img]) return
      this.countBase64ImgLoading += 1
      this.base64ImgObj[img] = await this.getUrlImgBase(img)
      this.countBase64ImgLoaded += 1
      this.refreshKey += 1
    },
    handleRowClick(row) {
      const route = this.$router.resolve({
        name: `product-${row.product_type === 'cho-thue' ? 'hired' : 'sell'}-detail`,
        params: { id: row.id },
      })
      window.open(route.href)
    },
    handleActionsButton(btn) {
      if (btn.value === this.ACTIONS_BUTTON.CLOSE.value) {
        this.$call(this.closeDealCustomer({ id: this.customer.id }), true)
          .then(res => {
            Object.assign(this.customer, res)
          })
      }
      if (btn.value === this.ACTIONS_BUTTON.OPEN.value) {
        this.$call(this.openDealCustomer(this.customer.id), true)
          .then(res => {
            Object.assign(this.customer, res)
          })
      }
      if (btn.value === this.ACTIONS_BUTTON.EDIT.value) {
        this.$router.push({
          name: `customer-${this.customerMenuType}-edit`,
          params: { id: this.$route.params.id },
        })
      }
    },
  },
  setup() {
    const CUSTOMER_STORE_MODULE_NAME = 'customer'
    if (!store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.registerModule(CUSTOMER_STORE_MODULE_NAME, customerStoreModule)
    onUnmounted(() => {
      if (store.hasModule(CUSTOMER_STORE_MODULE_NAME)) store.unregisterModule(CUSTOMER_STORE_MODULE_NAME)
    })

    const { renderProductContent } = useProduct()

    return {
      renderProductContent,
      ...useCustomerDetail(),
    }
  },
}
</script>

<style lang="scss">
.product-badge {
  position: absolute;
  right: .25rem;
  font-size: .7rem !important;

  &.is-exported {
    top: .25rem;
  }
}

.pdf-content {
  background-color: white;

  .top-banner {
    width: 100%;
    max-width: 100%;
  }

  .top-info {
    padding-left: 1rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  .product-item {
    padding: 1rem;
    position: relative;

    .product-title {
      font-weight: bold;
      display: block;
      margin-bottom: 1rem;
      border-left: 4px solid;
      background: #f1fcff;
      padding: 0.5rem;
    }

    .product-item-user {
      img {
        min-width: 60px;
        height: 60px;
        border-radius: 50%;
        object-fit: cover;
      }
    }

    .product-content {
      display: flex;
      justify-content: space-between;

      .featured {
        border: 4px solid #51bcda;
      }

      .featured, .product-image {
        width: 160px;
        height: 160px;
      }

      p {
        margin-bottom: .25rem;
      }
    }

    .product-content-images {
      display: flex;

      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border: 2px solid white;
      }
    }

    .product-featured {
      position: absolute;
      bottom: 2rem;
      right: 0;
    }
  }
}

.modal-body {
  height: 80vh;
  overflow-y: auto;
}

.quill-editor {
  border: 1px solid #dcdcdc;
  border-radius: 0.25rem;
}
</style>
